import React from 'react';
import { graphql } from "gatsby";
import "@assets/scss/App.scss";
import Layout from "@components/layout";
import Header from "@components/header/Header";
import ContentHeaderImageBtn from "@components/modules/content-header-image/content-header-image-btn";
import ContentHeaderImage from "@components/modules/content-header-image/content-header-image";
import PopularSearch from "@components/popular-search/index";
import Footer from "@components/footer/";
import SEO from "@components/seo";

import ArticleText from "@components/article-text/";
import MoreBlogPosts from "@components/modules/more-blog-posts/post-from-query";
import ModuleRender from "@components/modules/module-render/";
import FloatingBtn from "@components/floating-book-btn/";

const ArticlePages = ({pageContext, data, location}, props) => {
    let headerClass;
    if (data.strapiBlogs.BannerImage.headerType === 'HeaderImageLinks') {
        headerClass = " header-transparent header-transparent-cta";
    } else if (data.strapiBlogs.BannerImage.headerType === 'HeaderImageButtons') {
        headerClass = " header-transparent";
    } else {
        headerClass = "";
    }

    return (
        <Layout>
            <SEO title={data.strapiBlogs.Pagename}
            description={data.strapiBlogs.MetadataContent ? data.strapiBlogs.MetadataContent.MetaDescription : null}
            image={data.strapiBlogs.MetadataContent && data.strapiBlogs.MetadataContent.MetaImage ? data.strapiBlogs.MetadataContent.MetaImage.childImageSharp.resize : data.strapiBlogs.BannerImage.Image?.childImageSharp?.fluid}
            />

            <Header classNameHeader={headerClass} />

            {(data.strapiBlogs.BannerImage.headerType === 'HeaderImageLinks') &&
                <ContentHeaderImage
                    Title={data.strapiBlogs.Title}
                    imgUrl={data.strapiBlogs.BannerImage.Image?.childImageSharp?.fluid}
                    ButtonData={data.strapiBlogs.BannerImage.Button}
                    pageName_1="Help & Advice"
                    pageLink_1="/help-and-advice/"
                    pageName_2={data.strapiBlogs.category.name}
                    pageLink_2={`/help-and-advice/${data.strapiBlogs.category.Slug}`}
                    pageName_3={data.strapiBlogs.Title}
                    lastClass_3="last"
                    HideBreadcrumbs={data.strapiBlogs.BannerImage.HideBreadcrumbs}
                    showDate={data.strapiBlogs.showDate}
                    showShare={data.strapiBlogs.showShare}
                    sharePage={'blog'}
                    publishDate={data.strapiBlogs.publishDate}
                    pageLocation={location}
                    pageTitle={data.strapiBlogs.Title}
                    desktopImageAlign={data.strapiBlogs.BannerImage.desktopImageAlign}
                    tabletImageAlign={data.strapiBlogs.BannerImage.tabletImageAlign}
                    mobileImageAlign={data.strapiBlogs.BannerImage.mobileImageAlign}
                />
            }
            {(data.strapiBlogs.BannerImage.headerType === 'HeaderImageButtons') && 
                <ContentHeaderImageBtn
                    Title={data.strapiBlogs.Title}
                    imgUrl={data.strapiBlogs.BannerImage.Image?.childImageSharp?.fluid}
                    ButtonData={data.strapiBlogs.BannerImage.Button}
                    pageName_1="Help & Advice"
                    pageLink_1="/help-and-advice/"
                    pageName_2={data.strapiBlogs.category.name}
                    pageLink_2={`/help-and-advice/${data.strapiBlogs.category.Slug}`}
                    pageName_3={data.strapiBlogs.Title}
                    lastClass_3="last"
                    HideBreadcrumbs={data.strapiBlogs.BannerImage.HideBreadcrumbs}
                    desktopImageAlign={data.strapiBlogs.BannerImage.desktopImageAlign}
                    tabletImageAlign={data.strapiBlogs.BannerImage.tabletImageAlign}
                    mobileImageAlign={data.strapiBlogs.BannerImage.mobileImageAlign}
                />
            }
            {data.strapiBlogs.articleText &&
                <ArticleText
                    Title={data.strapiBlogs.articleText ? data.strapiBlogs.articleText.Title : null}
                    text={data.strapiBlogs.articleText ? data.strapiBlogs.articleText.articleText : null}
                    membersData={data.strapiBlogs.teams}
                    sidebarData={data.strapiBlogs.sidebar}
                    pageLocation={location}
                    pageTitle={data.strapiBlogs.Title}
                    showShare={data.strapiBlogs.showShare}
                    sharePage={'blog'}
                />
            }
            {data.allStrapiBlogs.edges.length > 1 &&
                <MoreBlogPosts
                    sectionTitle="Similar Help & Advice"
                    excludeId={data.strapiBlogs.id}
                    allStrapiBlogs={data.allStrapiBlogs}
                />
            }
            {data.strapiBlogs.selectModules &&
                <ModuleRender
                    moduleData={data.strapiBlogs.selectModules}
                />
            }
            <PopularSearch {...props}/>
            <Footer />
            <FloatingBtn />
        </Layout>
    );
}

export default ArticlePages

export const query = graphql`
query BlogQuery($slug: String!, $catName: String!) {
    strapiBlogs(Slug: {eq: $slug}, Published: {eq: true}) {
        id
        Pagename
        Slug
        Title
        publishDate
        showDate
        showShare
        category {
            Slug
            name
        }
        MetadataContent {
            MetaDescription
            MetaTitle
            MetaImage {
                childImageSharp {
                    resize(width: 1200) {
                        src
                        height
                        width
                    }
                }
            }
        }
        BannerImage {
            id
            mobileImageAlign
            tabletImageAlign
            desktopImageAlign
            headerType
            HideBreadcrumbs
            Button {
                id
                Label
                targetLink
                externalLink
                page {
                    Slug
                }
            }
            Image {
                childImageSharp {
                    fluid(quality: 80, toFormat: JPG, maxWidth: 1570, maxHeight: 582, cropFocus: CENTER, fit: COVER, background: "#fff") {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
        }
        articleText {
            Title
            articleText
        }
        sidebar {
            hideSaveBtn
            hideModuleBg
            sidebarButtons {
                Label
                id
                targetLink
                externalLink
                page {
                    Slug
                }
            }
            sidebarVideo {
                id
                showVideo
                videoask
                videoChannel
                videoId
                videoTitle
                posterImage {
                    childImageSharp {
                        fluid(quality: 80, maxWidth: 400) {
                            ...GatsbyImageSharpFluid_withWebp_noBase64
                        }
                    }
                }
            }
        }
        teams {
            id
            CalendlyLink
            BookVideoLabel
            Image {
                childImageSharp {
                    fixed(width: 70, height: 70) {
                        ...GatsbyImageSharpFixed_withWebp_noBase64
                    }
                }
            }
            Name
            PhoneNumber
        }
        selectModules {
            id
            modules {
                id
                moduleName
                component
                sectionClass
                moduleType {
                    id
                    Title
                    Text
                    Video {
                        showVideo
                        videoChannel
                        videoId
                    }
                    Buttons {
                        id
                        Label
                        targetLink
                        externalLink
                        page {
                            Slug
                        }
                    }
                    Image {
                        childImageSharp {
                            fluid(quality: 80, maxWidth: 620) {
                                ...GatsbyImageSharpFluid_withWebp_noBase64
                            }
                        }
                    }
                }
            }
        }
    }
    allStrapiBlogs(filter: {Published: {eq: true}, category: {name: {eq: $catName}}}, limit: 4) {
        edges {
            node {
                BannerImage {
                    Image {
                        childImageSharp {
                            id
                            fluid(quality: 80, maxWidth: 400, maxHeight: 240, cropFocus: CENTER, fit: COVER, background: "#fff") {
                                ...GatsbyImageSharpFluid_withWebp_noBase64
                            }
                        }
                    }
                }
                id
                Slug
                Title
                articleText {
                    articleText
                }
                category {
                    name
                    labelColour
                    labelFontColour
                    labelName
                    Slug
                }
            }
        }
    }
}
`